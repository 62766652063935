//
//loader.scss
//

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    z-index: 99999;
    transition: all 0.5s ease;
    visibility: visible;
    opacity: 1;

    #status {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

#status {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(var(--#{$prefix}primary-rgb), 0.15);

    ul {
        display: flex;

        li {
            list-style: none;
            height: 20px;
            width: 20px;
            background-color: var(--#{$prefix}primary);
            margin: 0 4px;
            border-radius: 50%;
            animation: bounce 1s linear infinite;

            &:nth-child(1) {
                animation-delay: 0.2s;
                background-color: darken($primary, 25%);
            }

            &:nth-child(2) {
                animation-delay: 0.6s;
                background-color: darken($primary, 20%);
            }

            &:nth-child(3) {
                animation-delay: 0.1s;
                background-color: darken($primary, 15%);
            }

            &:nth-child(4) {
                animation-delay: 0.8s;
                background-color: darken($primary, 10%);
            }

            &:nth-child(5) {
                animation-delay: 0.5s;
                background-color: darken($primary, 05%);
            }

            &:nth-child(6) {
                animation-delay: 0.1s;
                background-color: var(--#{$prefix}primary);
            }
        }
    }

    @keyframes bounce {
        0% {
            transform: translateY(0px);
        }

        50% {
            transform: translateY(-40px);
        }

        100% {
            transform: translateY(0px);
        }
    }
}