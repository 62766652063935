//
//job-list.scss
//

.job-list-menu {
    background-color: var(--#{$prefix}body-bg);
    box-shadow: var(--#{$prefix}box-shadow);
    border-radius: 10px;
    padding: 5px;
    justify-content: center;
    align-items: center;

    .nav-item {
        .nav-link {
            padding: 10px 18px;
            color: var(--#{$prefix}dark);
            font-weight: 500;
            transition: all 0.5s ease;
            border-radius: 10px;

            &.active {
                color: $white;
                background-color: var(--#{$prefix}primary);
            }
        }
    }
}

.job-box {
    overflow: hidden;
    transition: all 0.5s ease;

    .bookmark-label {
        width: 200px;
        position: absolute;
        top: -5px;
        left: -84px;
        transform: rotate(-45deg);
        z-index: 1;
        color: $white;
        background: rgba(var(--#{$prefix}primary-rgb), 0.15);
        transition: all 0.5s ease;
        padding: 6px;
        font-size: 20px;
        padding-top: 8px;
    }

    &:hover {
        transform: translateY(-8px);
        border-color: var(--#{$prefix}primary);

        .bookmark-label {
            background-color: var(--#{$prefix}primary);
        }
    }
}

//job-list page

.filler-job-form {
    i {
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
        left: 20px;
        z-index: 1;
        color: var(--#{$prefix}primary);
        font-size: 20px;
    }
}

.filter-job-input-box,
.filter-job-input-box.form-select,
.filler-job-form .choices__inner {
    box-shadow: none !important;
    font-size: 14px;
    height: 44px;
    padding: 6px 6px 6px 45px;
    border-radius: 6px;

    &:focus {
        border-color: var(--#{$prefix}border-color);
    }
}


.side-bar {
    .custom-control {
        margin: 12px 22px;
    }

    .form-check-input {
        width: 16px;
        height: 16px;
    }

    .accordion {
        .accordion-item {
            border: none;

            .accordion-button {
                background-color: rgba(var(--#{$prefix}primary-rgb), 0.15);
                color: var(--#{$prefix}dark);
                box-shadow: none;
                font-size: 15px;
                font-weight: $font-weight-medium;

                &::after {
                    content: "\F0140";
                    font-family: 'Material Design Icons';
                    background: none;
                }
            }
        }
    }
}

.tag-cloud {
    color: $text-muted;
    background-color: var(--#{$prefix}light);
    transition: all 0.5s ease;

    &:hover {
        color: $white;
        background-color: var(--#{$prefix}primary);
    }
}

.noUi-horizontal {
    height: 10px;
    background: var(--#{$prefix}white);
    border-color: $gray-400;

    .noUi-handle {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        border-color: var(--#{$prefix}primary);
        background: var(--#{$prefix}primary);
        box-shadow: none;

        &::after,
        &::before {
            content: "";
            opacity: 0;
        }
    }
}

.job-list-header {
    .registration-form-box {

        .form-control,
        .form-select {
            padding: 6px 8px 6px 45px;
        }
    }
}

.wedget-popular-title {
    .popular-box {
        position: relative;
        padding: 6px;
        border-radius: 5px;
        margin-top: 10px;
        border: 1px solid var(--#{$prefix}border-color);

        .number {
            text-align: center;
            width: 26px;
            height: 26px;
            line-height: 26px;
            border-radius: 5px;
            font-size: 14px;
            color: var(--#{$prefix}primary);
            background-color: rgba(var(--#{$prefix}primary-rgb), 0.15);
        }
    }
}

.favorite-icon {
    a {
        display: inline-block;
        width: 30px;
        height: 30px;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        border: 1px solid var(--#{$prefix}border-color);
        border-radius: 6px;
        color: rgba($gray-500, 0.55);
        transition: all 0.5s ease;

        &:hover {
            color: $white;
            background-color: $danger;
            border-color: $danger;
        }
    }
}

.bookmark-post,
.job-box.bookmark-post {
    .bookmark-label {
        background-color: var(--#{$prefix}primary);
    }

    .favorite-icon {
        a {
            background-color: $danger;
            color: $white;
            border-color: $danger;
        }
    }
}

.job-box {
    .favorite-icon {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

/*****************dark-mode******************/

body[data-bs-theme='dark'] {


    .noUi-horizontal {
        background-color: $gray-900;
        border-color: $gray-900;
        box-shadow: none;

        // .noUi-handle {
        //     border-color: $gray-dark-500;
        //     background-color: $gray-dark-500;
        //     box-shadow: none;
        // }
    }
}


@media (min-width: 640px) {

    .choices__list--dropdown .choices__item--selectable,
    .choices__list[aria-expanded] .choices__item--selectable {
        padding: 0;
        padding-left: 15px;
        padding-right: 29px;
    }

    .choices__list--dropdown .choices__item--selectable.is-highlighted,
    .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
        background-color: var(--#{$prefix}light);
    }
}